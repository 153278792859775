import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { PageLoader } from "../components/pageLoader";
import { useAppState } from "../state/appState";
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line
import BlockZone from "~blockZone";

// Components
import { ProjectScroll } from "../components/ui/projectScroll";

const ProjectPortal = ({ data: { page, projects } }) => {
    const {
        title,
        meta: { metaImage },
        pageBlocks,
    } = page || {};
    const { setNavDark } = useAppState();

    useEffect(() => {
        setNavDark(false);
    }, [setNavDark]);

    return (
        <div>
            {/* <ProjectScroll bannerImage={metaImage} title={title} {...projects} /> */}
            {pageBlocks && <BlockZone {...pageBlocks} />}
            <PageLoader />
        </div>
    );
};

export default ProjectPortal;

export const pageQuery = graphql`
    query projectPortalQuery {
        page: sanityWork {
            title
            meta {
                metaImage {
                    ...Image
                }
            }
            pageBlocks {
                ...BlockZone
            }
        }
        projects: allSanityProject(sort: { fields: order }) {
            edges {
                node {
                    meta {
                        metaImage {
                            ...Image
                        }
                    }
                    title
                    slug {
                        current
                    }
                    order
                }
            }
        }
    }
`;
